import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"
import styles from "./about.module.sass"
import { At } from '@styled-icons/boxicons-regular'


const About = () => (
  <Layout>
    <SEO title="About Me" />
    
    <article className={styles.about}>
      <Image fileName={"about/me.jpg"} />
      <header className={styles.about_header}>
        <h1 className={styles.about_title}>Shodai Kayama / カヤマ ショウダイ</h1>
      </header>

      {/* ---- About Me ---- */}
      <section className={styles.about_section}>
        <h3 className={styles.about_section_title}>About Me</h3>
        <p>
          Shodai Kayama — creative technologist & design researcher based in Tokyo.
          He designs new experience by weaving analog/digital interaction, storytelling and people’s creativity with playfulness.
        </p>
        <p>
          He graduated from MArch Design for Performance and Interaction at Bartlett School of Architecture, University College London.
          He always looks for collaborators and new opportunities that he can apply my interaction design skill. 
        </p>
        <p>
          His interest is designing new interaction and technology that naturally adapts to daily experience. 
          In order for this, his works are superficially simple and playful. 
          He believes this approach can broaden people’s perspectives about future interaction and technology. 
        </p>
        <p>
          Current CV is <a href="https://drive.google.com/file/d/1_uCCYYAqi9rBLkiTaH-hENUo2jv_SrOK/view?usp=sharing" target="_blank">here</a>.
        </p>

        {/* ---- About Me in JP ---- */}
        <section className={styles.about_section_aboutMeJP}>
          <h4 className={styles.about_section_aboutMeJP_title}>カヤマ　ショウダイ</h4>
          <p>
            デザイナー／エンジニア／リサーチャー。物語性や遊び心を用いて、アナログとデジタルの境界を跨いだインタラクションをデザインする。
            技術的要素を内包しつつシンプルさや親しみやすさを感じる形態を模索し日常レベルに溶け込む仕組みをデザインすることで、未来のコミュニケーションやテクノロジーへの新たな視点を提案している。
            2019年末、MArch Design for Performance and Interaction at Bartlett School of Architecture, University College London 修了。
          </p>
        </section>

        <h4 className={styles.about_section_subTitle}>What I do:</h4>
        <ul className="list_hex">
          <li>Developing alternative perspectives underpinned by theories and technologies from different fields</li>
          <li>Creating prototypes actually interacting with people</li>
          <li>Building new services and products</li>
        </ul>

        <h4 className={styles.about_section_subTitle}>Award / Exhibition:</h4>
        <ul className="list_hex">
          <li>2021 : Exhibition - Development project based on <Link to="works/hangedMan">The Hanged Man</Link>, <a href="https://www.inno.go.jp/news/20211125-OP2021.php" target="_blank"> OPEN INNO-vation 2021</a> (The installation was also on <a href="https://txbiz.tv-tokyo.co.jp/wbs/vod/post_242695" target="_blank">a TV news program, World Business Sattelite</a>)</li>
          <li>2021 : Award (Jury Selection) - <Link to="works/hangedMan">The Hanged Man</Link>, <a href="https://j-mediaarts.jp/award/single/hanger-like-interface-to-animate-clothes-the-hanged-man/" target="_blank">24th JAPAN MEDIA ARTS FESTIVAL</a></li>

          <li>2020 : Semi-finalist - Development idea based on <Link to="works/hangedMan">The Hanged Man</Link>, <a href="https://tokyo-startup.jp/qualifiers2020" target="_blank"> Tokyo Startup Gateway Contest 2020</a> organisied by Tokyo Metropolitan Government</li>
          <li>2020 : Adopted Project - Development idea based on <Link to="works/hangedMan">The Hanged Man</Link>, <a href="https://www.inno.go.jp/result/2020/hakai/challenger/" target="_blank">Disruptive Challenge Selection of INNO-vation progmram</a> organised by the Ministry of Internal Affairs and Communications</li>
          <li>2020 : Nomination - <Link to="works/hangedMan">The Hanged Man</Link>, <a href="https://www.inno.go.jp/result/2020/generation/nominate/" target="_blank">Generation Award category of INNO-vation progmram</a> organised by the Ministry of Internal Affairs and Communications</li>
          
          <li>2019 : Exhibition - <Link to="works/hangedMan">The Hanged Man</Link> at <a href="https://www.ucl.ac.uk/bartlett/architecture/exhibitions/fifteen" target="_blank">Fifteen, Bartlett School of Architecture graduation show</a>, London</li> {/*https://www.ucl.ac.uk/bartlett/architecture/hanged-man*/}
          <li>2019 : Installation - <Link to="works/hangedMan">The Hanged Man</Link> at Camden Market, London</li>
          <li>2019 : Talk Event - <Link to="works/hangedMan">The Hanged Man</Link> at Tate Britain, London</li>          
          <li>2019 : Exhibition - <Link to="works/re-fit">Re-fit</Link> at <a href="https://www.ucl.ac.uk/research/domains/neuroscience/events/brainpower" target="_blank">BrainPower, UCL Neuroscience Festival</a>, London (Team project working with Dalia Todary-Michael, Weichen Tang, Qiuyan Da, Jowin Foo and Kongpyung Moon)</li>

          <li>2019 : Exhibition - Naked (Prototype of <Link to="works/hangedMan">The Hanged Man</Link>) at <a href="https://www.samsung.com/uk/explore/kings-cross/whats-on/event/?id=EA12LR9KADS" target="_blank">Prototypes in Public@Samsung KX</a>, London</li>
          <li>2019 : Exhibition - Naked (Prototype of <Link to="works/hangedMan">The Hanged Man</Link>) at the project fair, UCL, Here East, London</li>

          <li>2012 : Award - The performance award in <a href="https://recruit-holdings.co.jp/news_data/release/2012/1116_6902.html" target="_blank">"Mashup Awards 8"
 organised by Recruit Holdings Co.,Ltd</a> (Team project working with <a href="https://twitter.com/jksoft913" target="_blank">@jksoft</a>)</li>
        </ul>


        <h4 className={styles.about_section_subTitle}>Experience</h4>
        <ul className="list_hex">
          <li>2022- : Design engineer at Design Center of Electronics Company in Tokyo (The detail is withheld for privacy)</li>
          <li>2020-2022 : Freelance interaction designer</li>
          <li>2018-2019 : MArch Design for Performance and Interaction at Bartlett School of Architecture, University College London</li>
          <li>2018 : Intern at <a href="http://www.nendo.jp/" target="_blank">Nendo Inc.</a></li>
          <li>2017-2018 : Freelance (Mainly worked with <a href="https://monstar-lab.com/bd/" target="_blank">Monstar Lab Bangladesh Ltd.</a>)</li>
          <li>2016-2017 : Graduate Diploma in Design at Goldsmiths, University of London</li>
          <li>2015-2016 : Study Abroad Art and Design Course at Japan College of Foreign Languages</li>
          <li>2011-2015 : Design technologist, Web engineer, Project manager at <a href="https://monstar-lab.com/global/" target="_blank">Monstar Lab, Inc.</a></li>
          <li>2007-2011 : Bachelor Media Science at Tokyo University of Technology</li>
        </ul>
      </section>

      {/* ---- Contact ---- */}
      <section className={styles.about_section}>
        <h3 className={styles.about_section_title} id="contact">Contact</h3>
        <p className={styles.about_text_email}>shodai.kayama <At size="14" style={{ margin: `-2px` }}/> gmail.com</p>
      </section>


      {/* ---- Public policy ---- */}
      <section className={styles.about_section_cookiePolicy}>
        <h4 className={styles.about_section_title}>About This Site</h4>
        <p>This site uses Google Analytics for access analysis and improvement of content. When viewing a page, information such as the accessed page, IP address, and cookies are automatically sent to Google. The collected data will not be used for purposes other than those mentioned.</p>
      </section>

    </article>

  </Layout>
)

export default About
